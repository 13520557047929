/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
    overflow: hidden;
  }

/* @font-face {
    font-family: "SourceSansPro-Regular";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-Regular.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-Black";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-Black.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-BlackIt";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-BlackIt.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-Bold";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-Bold.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-BoldItalic";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-BoldIt.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-ExtraLight";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-ExtraLight.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-ExtraLight";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-ExtraLightIt.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-It";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-It.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-Light";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-Light.otf) format("truetype");
}


@font-face {
    font-family: "SourceSansPro-SemiBold";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-Semibold.otf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro-SemiBoldIt";
    src: local("SourceSansPro"), url(./assets/fonts/SourceSansPro-SemiboldIt.otf) format("truetype");
}

* {
    font-family: 'SourceSansPro';
} */


* {
    font-family: 'Montserrat';
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Black";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-BlackItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-BoldItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-ExtraBold";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-ExtraBoldItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-ExtraLight";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-ExtraLightItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Italic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Light";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-LightItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-MediumItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-SemiBold";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-SemiBoldItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-Thin";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "Montserrat-ThinItalic";
    src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
}

.width100 {
    width: 100%;
}

.codeClass {
    /* overflow: visible !important; */
    /* width: auto !important; */
    min-width: 250px !important;
    /* max-height: fit-content !important; */
}

:root {
    --backgroundColor: #FDAF07;
    --fontColor: #ffffff;
}

.heading {
    color: #355F73;
    font-family: "Montserrat-SemiBold";
    font-weight: 600;
}

.mat-select-search-input {
    width: 80% !important;
    border: 1px solid #DCD4D4 !important;
    margin: 24px;
    background-color: #F5F5F5 !important;
    border-radius: 4px !important;
    height: 37px !important;
    font-size: 12px !important;
}

.mat-select-search-clear {
    position: absolute;
    right: 13px !important;
    top: 22px !important;
}

.mat-select-search-no-entries-found {
    font-size: 12px !important;
}

.mat-select-search-no-entries-found .mat-mdc-option mat-icon {
    font-size: 16px !important;
}

.swiper-pagination-bullet {
    margin: 0 2px;
}

.paginationbullets {
    bottom: 0px;
    position: relative;
    margin-top: 20px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 16px;
}

.mat-mdc-form-field-icon-suffix .mat-mdc-form-field-icon-prefix {
    padding: 0 0 10px 4px;
}

.mdc-icon-button {
    margin-top: -10px;
}

.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(173, 167, 167, 0.5);
    z-index: 999;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    content: "";
    width: 75px;
    height: 75px;
    display: inline-block;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
    animation: spin 500ms infinite linear;
    -o-animation: spin 500ms infinite linear;
    -ms-animation: spin 500ms infinite linear;
    -webkit-animation: spin 500ms infinite linear;
    -moz-animation: spin 500ms infinite linear;
    border-left-color: transparent;
    border-right-color: transparent;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    font-family: 'Montserrat' !important;
}

.book-btn {
    background: linear-gradient(179.06deg, rgba(239, 158, 0, 0.4) -37.46%, #EF9E00 99.19%);
    font-size: 16px;
    font-family: 'Montserrat-SemiBold';
    color: white;
    border: none;
    margin-top: 13px;
    width: 220px;
    height: 44px;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.9;
}

.grecaptcha-badge {
    bottom: 266px !important;
    z-index: 5;
}

.book-btn:hover {
    background: linear-gradient(179.06deg, rgba(239, 158, 0, 0.4) -37.46%, #EF9E00 99.19%);
    opacity: 1;
    box-shadow: 0px 4px 6px 0px #FFFFFF59 inset;
    box-shadow: 0px -4px 6px 0px #0000001F inset;
    transform: scale(1.01);
}



@keyframes spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spin {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes spin {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}